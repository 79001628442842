import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";

import './Login.css';
import logo from '../../images/2019_SA_Logo_Navy_468.png';

async function loginUser(credentials, setSessionToken) {
    const OK = 200;

    const response = await fetch("/api/login/admin", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(credentials)
    });

    if (response.status === OK) {
        const token = await response.json();
        setSessionToken(token);
        return "AUTHORIZED";
    }

    return "UNAUTHORIZED";
}

export default function AdminLogin({ setSessionToken }) {
    const { register, control, handleSubmit } = useForm();

    const [loginError, setLoginError] = useState(false);

    const onSubmit = async data => {
        let result = await loginUser({
            email: data.username,
            password: data.password,
            agencyId: data.tenant
        }, setSessionToken);

        // Dont call setLoginError if login was successful because then we wont be re-rendering anyway.
        // Otherise get "Can't perform a React state update on an unmounted component" message in console.
        if (result == "UNAUTHORIZED") {
            setLoginError(true);
        } 
    };

    let error = null;
    if (loginError) {
        error = (<div className="login-invalid">Not a valid username or password</div>);
    }

    return (
        <div className="login-wrapper">
            <img src={logo} alt="Special Agent, Inc." className="logo" />
            <div>Admin Login</div>
            <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                <div>
                    <label className="login-field-label">
                        <span className="login-field-label-text">Username</span>
                        <input type="text" className="login-field" {...register("username", { required: true })} />
                    </label>
                </div>
                <div>
                    <label className="login-field-label">
                        <span className="login-field-label-text">Password</span>
                        <input type="password" className="login-field" {...register("password", { required: true })} />
                    </label>
                </div>
                <div>
                    <label className="login-field-label">
                        <span className="login-field-label-text">Tenant</span>
                        <input type="text" className="login-field" {...register("tenant", { required: true })} />
                    </label>
                </div>
                <div>
                    <button type="submit" className="login-submit">LOGON</button>
                </div>
                {error}
            </form>
        </div>
    );
}

AdminLogin.propTypes = {
    setSessionToken: PropTypes.func.isRequired
}