import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { getSessionToken, getCurrentEmailFromToken } from '../../utils/TokenUtils';

import './Users.css'

const ConfirmMessage = ({ email }) => (
    <div>
        <div>
            A new temporary password will be created and sent to:
        </div>
        <div style={{ marginLeft: 25 }}>
            { email }
        </div>
    </div>
);

const CcCheckBox = ({ currentEmail, registerCheckBoxAs, register }) => (
    <div style={{ marginTop: 25 }}>
        <div>
            A copy of the email will also be sent to:
        </div>
        <div>
            <span>
                <input type="checkbox" {...register(registerCheckBoxAs)} />
                <label htmlFor={registerCheckBoxAs} className="user-edit-checkbox-label-text">{currentEmail}</label>
            </span>
        </div>
    </div>
);

export default function UserForgotPassword({ userEmail }) {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            "email": userEmail,
            "ccCurrentUser": true
        }
    });

    // If userEmail is falsy we can just return nothing
    if (!userEmail) {
        return "";
    }

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setValue("email", userEmail);
        setShow(true);
    }

    const currentEmail = getCurrentEmailFromToken();
    const ccCheck = currentEmail && userEmail != currentEmail ? <CcCheckBox currentEmail={currentEmail} registerCheckBoxAs="ccCurrentUser" register={register} /> : "";
    const onSubmit = async (data) => {
        if (show) {
            console.log(`submitted`);
        } else {
            console.log("DONT SUBMIT");
            return;
        }

        const requestData = { "email": data.email };
        if (data.ccCurrentUser && currentEmail) {
            requestData["cC"] = [currentEmail];
        }

        const token = getSessionToken();
        const response = await fetch("/api/users/reset", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        if (response.ok) {
            setShow(false);
        }
    };

    return (
        <div>
            <a href='#!' variant="primary" className="user-list-link" onClick={handleShow}>Reset Password</a>
            <Modal show={show} onHide={handleClose} centered dialogClassName="user-edit-modal-size" contentClassName="user-edit-modal-content">
                <Modal.Header closeButton>
                    <Modal.Title><h2>Reset Password</h2></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <input type="hidden" {...register("email")} />
                        <ConfirmMessage email={userEmail} />
                        {ccCheck}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="button-base" onClick={handleClose}>
                            Cancel
                        </button>
                        <button type="submit" className="button-base">
                            Ok
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

UserForgotPassword.propTypes = {
    userEmail: PropTypes.string.isRequired
}