import React from 'react';

import './PaymentMethods.css'
import { ReactComponent as Checkmark } from '../../images/checkmark.svg'

export default function PaymentMethodDefaultIndicator({ paymentMethod }) {

    if (!paymentMethod.isDefault) {
        return '';
    } else {
        return (
            <div className="paymentmethod-default-wrapper">
                <span>
                    <Checkmark className="paymentmethod-default-check" />
                    Default
                </span>
            </div>
        );
    }
}