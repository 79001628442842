import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { sentenceCase } from '../../utils/StringUtils';
import { getSessionToken } from '../../utils/TokenUtils';

import './BillingHistory.css'

function createInvoiceRow(invoice) {

    const date = new Date(invoice.created);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    const receiptPdfUrl = `/api/billing/receipt/${invoice.id}/pdf`;
    const receiptClick = (e) => {
        e.preventDefault();
        downloadReceipt(invoice.id);
    }

    const detailsLink = invoice.status.toLowerCase() == "void" ? '' : (<a href="" onClick={receiptClick} className="billinghistory-list-link">Details</a>);

    return (
        <tr key={invoice.id}>
            <td>{invoice.number}</td>
            <td>{formattedDate}</td>
            <td>{sentenceCase(invoice.status)}</td>
            <td>
                <CurrencyFormat value={invoice.amountDue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </td>
            <td>{detailsLink}</td>
        </tr>
    );
}

export default function BillingHistory({ invoices }) {

    let tableBody = '';

    if (!invoices) {
        tableBody = (
            <tbody>
                <tr>
                    <td colSpan="5">
                        <div className="loading-text">Loading...</div>
                    </td>
                </tr>
            </tbody>
        );
    } else if (invoices.length > 0) {
        tableBody = (
            <tbody>
                {invoices.map(invoice => createInvoiceRow(invoice))}
            </tbody>
        );
    } else {
        tableBody = (
            <tbody>
                <tr>
                    <td colSpan="5">
                        <div></div>
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <div>
            <h2 className="section-header">History</h2>
            <table className="billinghistory-table">
                <thead>
                    <tr>
                        <th width="25%">Invoice</th>
                        <th width="25%">Billing Date</th>
                        <th width="25%">Status</th>
                        <th width="15%">Amount</th>
                        <th width="10%"></th>
                    </tr>
                </thead>
                {tableBody}
            </table>
        </div>
    );
}

async function downloadReceipt(invoiceId) {

    const token = getSessionToken();
    const response = await fetch(`/api/billing/invoice/${invoiceId}/paymentpage`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    const data = await response.json();
    window.open(data.paymentPage);
}