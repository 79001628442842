import React, { Component, useState, useEffect } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Billing } from './components/Billing';
import { setSessionToken, getSessionToken, checkToken } from './utils/TokenUtils';
import Login from './components/Login/Login';
import AdminLogin from './components/Login/AdminLogin';

//import 'dotenv/config'

import './custom.css'

const login = (setAppToken) => {
    return (
        <Routes>
            <Route exact path='/billing' element={<Navigate to='/' />} />
            <Route exact path='/admin' element={<AdminLogin setSessionToken={setAppToken} />} />
            <Route path='/' element={<Login setSessionToken={setAppToken} />} />
        </Routes>
    );
}

const home = () => {
    return (
        <Layout>
            <Routes>
                <Route exact path='/admin' element={<Navigate to='/' />} />
                <Route path='/billing' element={<Billing stripePromise={stripePromise} />} />
                <Route path='/' element={<Home />} />
            </Routes>
        </Layout>
    );
}

// Make sure to call loadStripe outside of a component�s render to avoid
// recreating the Stripe object on every render.
// Info on getting dotenv to work
// https://github.com/motdotla/dotenv
// https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
// https://stackoverflow.com/questions/42182577/is-it-possible-to-use-dotenv-in-a-react-project
//const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
//console.log(stripePublishableKey);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function App() {
    const [token, setToken] = useState(getSessionToken());

    const setAppToken = (token) => {
        setSessionToken(token);
        setToken(token);
    };

    // https://stackoverflow.com/questions/46160461/how-do-you-set-the-document-title-in-react
    useEffect(() => {
        document.title = 'Special Agent - Control Panel';
    }, []);

    // Routing light bulb went off after actually reading docs!
    // https://v5.reactrouter.com/web/guides/primary-components

    if (!checkToken(token)) {
        return login(setAppToken);
    } else {
        return home();
    }
}