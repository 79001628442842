import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom'
import { sentenceCase } from '../../utils/StringUtils';

import './BillingSummary.css'

export default function BillingSummary({ support, parentName, databaseCount, userCount, defaultPaymentMethod }) {

    if (!support || userCount == 0 || databaseCount == 0) {
        return (
            <div>
                <h2 className="section-header">Billing</h2>
                <div className="billing-summary-wrapper">
                    <div className="loading-text row2 col2">
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    const total = support.supportAmount + (userCount * support.supportAmountPerUser);
    const userLabel = "user" + (userCount > 1 ? "s" : "");
    const databaseLabel = "database" + (databaseCount > 1 ? "s" : "");

    const today = new Date();
    let nextPaymentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds(), today.getMilliseconds());
    nextPaymentDate.setDate(support.monthDay)

    if (support.monthDay < today.getDate()) {
        nextPaymentDate.setMonth(today.getMonth() + 1);
    }

    let paymentMethod = '';
    let billingLink = (<Link to="/billing" state={{ support: support, parentName: parentName }}>Manage Billing</Link >);

    if (defaultPaymentMethod) {

        if (defaultPaymentMethod.card) {
            // https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
            const brand = sentenceCase(defaultPaymentMethod.card.brand);
            paymentMethod = `${brand} ending in ${defaultPaymentMethod.card.last4}`;
        } else {
            paymentMethod = `Account ending in ${defaultPaymentMethod.usBankAccount.last4}`;
        }
    }

    return (
        <div>
            <h2 className="section-header">Billing</h2>
            <div className="billing-summary-wrapper">
                <div className="row1 monthly-cost-wrapper">
                    <h2 className="col1">
                        <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </h2>
                    <div className="col2 monthly-cost-label">/mo (estimated)</div>
                </div>

                <div className="row2">{databaseCount} {databaseLabel}</div>
                <div className="row3">{userCount} active {userLabel}</div>

                <div className="row1 col3 right-align bottom-align">Autopay on {nextPaymentDate.toLocaleDateString()}</div>
                <div className="row2 col3 right-align">{paymentMethod}</div>
                <div className="row3 col3 right-align">{billingLink}</div>

            </div>
        </div>
    );
}