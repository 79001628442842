import React, { useState } from 'react';
import PaymentMethodAdd from './PaymentMethodAdd';
import PaymentMethodDefaultIndicator from './PaymentMethodDefaultIndicator'
import PaymentMethodLogo from './PaymentMethodLogo'
import Modal from 'react-bootstrap/Modal';
import { sentenceCase } from '../../utils/StringUtils';
import { getSessionToken } from '../../utils/TokenUtils';

import './PaymentMethods.css'

function createPaymentMethodTableBody(paymentMethods, resetDefault, setShowRemoveConfirm) {
    if (!paymentMethods) {
        return (
            <tbody>
                <tr>
                    <td colSpan="5">
                        <div className="loading-text">Loading...</div>
                    </td>
                </tr>
            </tbody>
        );
    } else if (paymentMethods.length > 0) {
        return (
            <tbody>
                {paymentMethods.map(paymentMethod => createPaymentMethodRow(paymentMethod, resetDefault, setShowRemoveConfirm))}
            </tbody>
        );
    } else {
        return (
            <tbody>
                <tr>
                    <td colSpan="5">
                        <div></div>
                    </td>
                </tr>
            </tbody>
        );
    }
}

function getPaymentMethodName(paymentMethod) {
    if (!paymentMethod) {
        return '';
    } else if (paymentMethod.card) {
        return sentenceCase(paymentMethod.card.brand);
    } else {
        return paymentMethod.usBankAccount.bankName;
    }
}

function getPaymentMethodLast4(paymentMethod) {
    if (!paymentMethod) {
        return '';
    } else if (paymentMethod.card) {
        return paymentMethod.card.last4;
    } else {
        return paymentMethod.usBankAccount.last4;
    }
}

function getPaymentMethodExpiration(paymentMethod) {
    if (paymentMethod.card) {
        return `${paymentMethod.card.expMonth}/${paymentMethod.card.expYear}`;
    } else {
        return 'n/a';
    }
}

function getDefaultPaymentMethodId(paymentMethods) {
    if (paymentMethods && paymentMethods.length > 0) {
        const pm = paymentMethods.find(i => i.isDefault);
        if (pm) {
            return pm.id;
        }
    }
    return null;
}

function createPaymentMethodRow(paymentMethod, handleSetDefaultClick, handleRemoveClick) {

    const name = getPaymentMethodName(paymentMethod);
    const last4 = getPaymentMethodLast4(paymentMethod);
    const expiration = getPaymentMethodExpiration(paymentMethod);

    const setDefaultClick = async (e) => {
        e.preventDefault();

        const response = await fetch(`/api/billing/paymentmethod/${paymentMethod.id}/default`, {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${getSessionToken()}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            handleSetDefaultClick(paymentMethod.id);
        }
    };

    const removeClick = async (e) => {
        e.preventDefault();
        handleRemoveClick(paymentMethod);
    }

    let actionLinks = '';
    if (!paymentMethod.isDefault) {
        actionLinks = (
            <span className="paymentmethod-container">
                <div>
                    <a href="" onClick={setDefaultClick} className="paymentmethod-list-link">Set Default</a>
                </div>
                &nbsp;|&nbsp;
                <div>
                    <a href="" onClick={removeClick} className="paymentmethod-list-link">Remove</a>
                </div>
            </span>
        );
    }

    return (
        <tr key={paymentMethod.id}>
            <td>
                <PaymentMethodLogo paymentMethodName={name} />
            </td>
            <td>{name} {last4}</td>
            <td>{expiration}</td>
            <td>
                <PaymentMethodDefaultIndicator paymentMethod={paymentMethod} />
            </td>
            <td>{actionLinks}</td>
        </tr>
    );
}

export default function PaymentMethods({ paymentMethods, setPaymentMethodsState, stripePromise, handlePaymentMethodAdded }) {
    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(getDefaultPaymentMethodId(paymentMethods));
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
    const [pmToRemove, setPmToRemove] = useState(null);

    const handleAddClick = async () => {
        setShowAddPaymentMethod(true);
    };
    const handleAddClose = () => {
        setShowAddPaymentMethod(false);
    };
    const handleSetDefaultClick = (newId) => {
        paymentMethods.forEach(i => i.isDefault = false);
        const newDefault = paymentMethods.find(i => i.id == newId);
        newDefault.isDefault = true;
        setDefaultPaymentMethodId(newId);
    };
    const handleRemoveClick = (paymentMethod) => {
        setPmToRemove(paymentMethod);
        setShowRemoveConfirm(true);
    };
    const handleRemoveConfirmClose = () => {
        setPmToRemove(null);
        setShowRemoveConfirm(false);
    };
    const handleSubmitRemove = async () => {

        const response = await fetch(`/api/billing/paymentmethod/${pmToRemove.id}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${getSessionToken()}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            // https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array-in-javascript
            const newPaymentMethods = paymentMethods.filter(i => i.id != pmToRemove.id);
            setPaymentMethodsState(newPaymentMethods);
            setPmToRemove(null);
            setShowRemoveConfirm(false);
        }
    };

    const tableBody = createPaymentMethodTableBody(paymentMethods, handleSetDefaultClick, handleRemoveClick);

    return (
        <div>
            <span className="user-header-container">
                <span className="paymentmethod-label-container">
                    <h2 className="section-header">Payment Methods</h2>
                </span>
                <button onClick={handleAddClick} className="paymentmethod-add-button button-base">Add Payment Method</button>
            </span>
            <table className="paymentmethod-table">
                <thead>
                    <tr>
                        <th width="10%">Type</th>
                        <th width="33%">Name</th>
                        <th width="25%">Expiration</th>
                        <th width="10%"></th>
                        <th width="22%"></th>
                    </tr>
                </thead>
                {tableBody}
            </table>

            <Modal show={showRemoveConfirm} onHide={handleRemoveConfirmClose} centered
                dialogClassName="paymentmethod-remove-confirm-modal-size"
                contentClassName="paymentmethod-remove-confirm-modal-content">
                <Modal.Header closeButton>
                    <Modal.Title><h2>Remove {getPaymentMethodName(pmToRemove)}</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="paymentmethod-confirm-message-container">
                        Confirm removing payment method {getPaymentMethodName(pmToRemove)} {getPaymentMethodLast4(pmToRemove)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-base" onClick={handleRemoveConfirmClose}>
                        Cancel
                    </button>
                    <button className="button-base" onClick={handleSubmitRemove}>
                        OK
                    </button>
                </Modal.Footer>
            </Modal>

            <PaymentMethodAdd show={showAddPaymentMethod} handleClose={handleAddClose} stripePromise={stripePromise} handlePaymentMethodAdded={handlePaymentMethodAdded} />
        </div>
    );
}