import React from 'react';
import { Container } from 'reactstrap';

import './PageFooter.css';

export default function PageFooter() {
    return (
        <div className="footer-wrapper">
            <Container>
                <div className="vertical-center footer-text">
                    &copy; 2024 Special Agent Inc. All rights reserved
                </div>
            </Container>
        </div>
    )
}