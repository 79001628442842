import React from 'react';
import { getCurrentParentIdFromToken } from '../../utils/TokenUtils'

import './Databases.css'

export default function Databases({ databases }) {

    let tableBody = (
        <tbody>
            {databases.map(db =>
                <tr key={db.id}>
                    <td>{db.agencyName}</td>
                    <td />
                </tr>
            )}
        </tbody>
    );

    if (!databases || databases.length == 0) {
        tableBody = (
            <tbody>
                <tr>
                    <td colSpan="2">
                        <div className="loading-text">Loading...</div>
                    </td>
                </tr>
            </tbody>
        );
    }

    const installerDownloadHref = `/api/installer/${getCurrentParentIdFromToken()}`;

    return (
        <div>
            <span className="database-header-container">
                <h2 className="section-header">Databases</h2>
                <a href={installerDownloadHref} variant="primary" download="SpecialAgentCloudWorkstationSetup.msi" className="button-base database-install">Install Windows App</a>
            </span>
            <table className="database-table">
                {tableBody}
            </table>
        </div>
    );
}