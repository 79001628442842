import React, { useEffect, useState } from 'react';
import PaymentMethodAddForm from './PaymentMethodAddForm';
import Modal from 'react-bootstrap/Modal';
import { Elements } from "@stripe/react-stripe-js";
import { getSessionToken } from '../../utils/TokenUtils';

export default function PaymentMethodAdd({ show, handleClose, stripePromise, handlePaymentMethodAdded }) {

    const [clientSecret, setClientSecret] = useState(null);

    // The following question helped fix useEffect only executing once, ever
    // https://stackoverflow.com/questions/62631053/useeffect-being-called-multiple-times
    // It should now execute anytime clientSecret is set, with a guard clause to not run if it already has a value
    useEffect(() => {
        if (clientSecret) {
            return;
        }

        async function fetchClientSecret() {
            const response = await fetch(`/api/billing/paymentmethod/setup`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${getSessionToken()}`,
                    'Content-Type': 'application/json'
                }
            });

            const json = await response.json();
            setClientSecret(json.clientSecret);
        };

        fetchClientSecret();
    }, [clientSecret]);

    const handleCloseInternal = () => {
        setClientSecret(null);
        handleClose();
    };

    const elementsAppearance = { theme: 'stripe' };
    let elementsOptions = {
        clientSecret: clientSecret,
        appearance: elementsAppearance
    };

    return (
        <div>
            <Modal show={show} onHide={handleCloseInternal} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h2>Add Payment Method</h2></Modal.Title>
                </Modal.Header>
                {clientSecret && (<Elements options={elementsOptions} stripe={stripePromise}>
                    <PaymentMethodAddForm handleClose={handleCloseInternal} handlePaymentMethodAdded={handlePaymentMethodAdded} />
                </Elements>)}
            </Modal>
        </div>
    );
}