import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserEdit from './UserEdit'
import UserForgotPassword from './UserForgotPassword'

import './Users.css'

function createUserRow(user, roles, onEditClose) {

    let userLinks = (<span className="user-link-container">
        <UserForgotPassword userEmail={user.email} />
        &nbsp;|&nbsp;
        <UserEdit user={user} roles={roles} onClose={onEditClose} />
    </span>);

    let userClassName = 'user-normal';
    if (getCountOfAccessibleTenants(user) === 0) {
        userClassName = 'user-inactive';
        userLinks = (<span className="user-link-container">
            <UserEdit user={user} roles={roles} onClose={onEditClose} />
        </span>);
    } else if (user.roleName === 'Admin') {
        userClassName = 'user-admin';
    }

    return (
        <tr key={user.email}>
            <td><div className={userClassName}>{user.fullName}</div></td>
            <td><div className={userClassName}>{user.email}</div></td>
            <td><div className={userClassName}>{user.roleName}</div></td>
            <td><div className={userClassName}>{calculateUserStatusDesc(user)}</div></td>
            <td>{userLinks}</td>
        </tr>
    );
}

function calculateUserStatusDesc(user) {
    const accessibleTenantCount = getCountOfAccessibleTenants(user);
    if (accessibleTenantCount == 0) {
        return (<div>Not Active</div>);
    } else if (accessibleTenantCount == 1) {
        return (<div>Active</div>);
    } else {
        return (
            <div className='user-active-container'>
                Active <div className='user-active-count-badge'>{accessibleTenantCount}</div>
            </div>);
    }
}

function getCountOfAccessibleTenants(user) {
    const accessibleTenants = user.agencyTenants.filter(tenant => tenant.accessAllowed);
    return accessibleTenants.length;
}

function compareUserName(a, b) {
    var aValue = a.fullName ? a.fullName.toLowerCase() : '';
    var bValue = b.fullName ? b.fullName.toLowerCase() : '';
    if (aValue < bValue) {
        return -1;
    }
    if (aValue > bValue) {
        return 1;
    }

    return 0;
}

function compareUserEmail(a, b) {
    if (a.email.toLowerCase() < b.email.toLowerCase()) {
        return -1;
    }
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
        return 1;
    }

    return 0;
}

export default function Users({ users, roles, databases, onEditClose }) {
    const tenants = databases.map(db => { return { agencyTenantId: db.id, agencyName: db.agencyName, isAdmin: false, accessAllowed: true } });
    const newRole = roles.find(r => r.name == 'User');
    const roleId = newRole ? newRole.id : ''
    const roleName = newRole ? newRole.Name : '';
    const newUser = { fullName: "", email: "", roleId: roleId, roleName: roleName, status: "", agencyTenants: tenants};

    const [viewAll, setViewAll] = useState(false);
    const [isSortDesc, setIsSortDesc] = useState(false);
    const [sortColumn, setSortColumn] = useState("name");

    let renderUsers = users;
    let userFilter = (
        <span className="user-header-container user-filter-container">
            <div>View All |&nbsp;</div>
            <a href='#!' variant="primary" onClick={() => setViewAll(false)}>Active</a>
        </span>
    );

    if (!viewAll) {
        renderUsers = users.filter(u => u.agencyTenants.find(tenant => tenant.accessAllowed));
        userFilter = (
            <span className="user-header-container user-filter-container">
                <a href='#!' variant="primary" onClick={() => setViewAll(true)}>View All</a>
                <div>&nbsp;| Active</div>
            </span>
        );
    }

    const handleSort = (column) => {
        if (column == sortColumn) {
            setIsSortDesc(!isSortDesc);
        } else {
            setSortColumn(column);
        }
    }

    let nameHeader = (<a href='#!' className="user-sort-link" onClick={() => handleSort('name')}>Name</a>);
    let emailHeader = (<a href='#!' className="user-sort-link" onClick={() => handleSort('email')}>Email</a>);

    let sortIndicator = isSortDesc ? <div className="arrow-up" /> : <div className="arrow-down"/>;
    if (sortColumn == 'name') {
        renderUsers = Array.prototype.toSorted.call(renderUsers, compareUserName);
        nameHeader = (
            <span className="user-sort-container">{nameHeader}{sortIndicator}</span>
        );
    } else if (sortColumn == 'email') {
        renderUsers = Array.prototype.toSorted.call(renderUsers, compareUserEmail);
        emailHeader = (
            <span className="user-sort-container">{emailHeader}{sortIndicator}</span>
        );
    }

    if (isSortDesc) {
        renderUsers = renderUsers.reverse();
    }
    let tableBody = (
        <tbody>
            {renderUsers.map(user => createUserRow(user, roles, onEditClose))}
        </tbody>
    );

    if (users.length == 0) {
        tableBody = (
            <tbody>
                <tr>
                    <td colSpan="5">
                        <div className="loading-text">Loading...</div>
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <div>
            <span className="user-header-container">
                <span className="user-label-container">
                    <h2 className="section-header user-header-text">Users</h2>
                    {userFilter}
                </span>
                <UserEdit user={newUser} roles={roles} onClose={onEditClose} />
            </span>
            <table className="user-table">
                <thead>
                    <tr>
                        <th width="25%">{nameHeader}</th>
                        <th width="33%">{emailHeader}</th>
                        <th width="15%">Role</th>
                        <th width="10%">Status</th>
                        <th width="17%"></th>
                    </tr>
                </thead>
                { tableBody }
            </table>
        </div>
    );
}

Users.propTypes = {
    users: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    databases: PropTypes.array.isRequired,
    onEditClose: PropTypes.func.isRequired
}