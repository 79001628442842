export const setSessionToken = (userToken)  => {
    const jsonToken = JSON.stringify(userToken);
    sessionStorage.setItem('token', jsonToken);
}

export const getSessionToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
}

export const clearSessionToken = () => {
    sessionStorage.setItem('token', null);
}

export const checkToken = (token) => {

    if (token) {
        setTimeout(checkSessionTokenExpiration, 5000);
        return true;
    } else {
        return false;
    }
}

export const checkSessionTokenExpiration = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    if (!userToken.expires) {
        setTimeout(checkSessionTokenExpiration, 5000);
    } else {
        const current = new Date();
        const expire = new Date(userToken.expires);

        if (current >= expire) {
            sessionStorage.setItem('token', null);
            window.location.reload();
        } else {
            setTimeout(checkSessionTokenExpiration, 5000);
        }
    }
}

export const getCurrentEmailFromToken = () => {
    const tokenData = getParsedJwtPayload();
    const currentEmail = tokenData ? tokenData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] : '';

    return currentEmail;
}

export const getCurrentParentIdFromToken = () => {
    const tokenData = getParsedJwtPayload();
    const currentEmail = tokenData ? tokenData["ParentAgentId"] : '';

    return currentEmail;
}

export const getCurrentRoleFromToken = () => {
    const email = getCurrentEmailFromToken().toLowerCase();
    if (email == "admin@specialagent.com" || email == "admin@agents.specialagent.com") {
        return "Admin";
    }

    const tokenData = getParsedJwtPayload();
    const currentRole = tokenData ? tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] : '';

    return currentRole;
}

function getParsedJwtPayload() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    if (!userToken) {
        return null;
    }
    const token = userToken.token;

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}