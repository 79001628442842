export const sentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getOrdinal = (num) => {
    const str = num + '';
    let ordinal = '';

    if (str.endsWith('1')) {
        ordinal = `${num}st`;
    } else if (str.endsWith('2')) {
        ordinal = `${num}nd`;
    } else if (str.endsWith('3')) {
        ordinal = `${num}rd`;
    } else {
        ordinal = `${num}th`;
    }

    return ordinal;
}