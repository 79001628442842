import React from 'react';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { getSessionToken } from '../../utils/TokenUtils';

export default function BillingCustomerContactChange({ currentEmail, show, onClose, onSubmit }) {
    //console.log(email);
    const defaultEmail = currentEmail ? currentEmail : '';
    const { register, handleSubmit } = useForm({
        defaultValues: { email: defaultEmail }
    });

    const handleChangeContactSubmit = async (data) => {

        const response = await fetch("/api/billing/customer/email", {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${getSessionToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            onSubmit(data.email);
            onClose();
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
            <Modal.Title><h2>Change Contact</h2></Modal.Title>
        </Modal.Header>

            <form onSubmit={handleSubmit(handleChangeContactSubmit)} >
                <Modal.Body>
                    <label className="billing-contact-change-field-label">
                        <div className="billing-contact-change-field-label-text">Email</div>
                        <input className="billing-contact-change-field" type="text" {...register("email", { required: true })} />
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-base" onClick={onClose}>Cancel</button>
                    <button className="button-base" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
