import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { getSessionToken } from '../../utils/TokenUtils';

function buildEmailInput(user, register) {
    if (user.id) {
        return (<input className="user-edit-field input-readonly" type="text" readOnly {...register("email")} required />);
    } else {
        return (<input className="user-edit-field" type="text" {...register("email")} required />);
    }
}

const TextInput = ({ label, registerAs, register, required }) => (
    <>
        <label className="user-edit-field-label">
            <div className="user-edit-field-label-text">{label}</div>
            <input className="user-edit-field" type="text" {...register(registerAs, { required })} />
        </label>
    </>
);

export default function UserForm({ user, roles, onClose, onCancel }) {
    const { register, control, handleSubmit } = useForm({
        defaultValues: user
    });

    const { fields } = useFieldArray({
        control,
        name: "agencyTenants"
    });

    const onSubmit = async (data) => {
        const jsonData = JSON.stringify(data);

        let method = "PATCH";
        if (!data.id) {
            method = "POST";
        }

        const token = getSessionToken();
        const response = await fetch("/api/users", {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: jsonData
        });

        if (response.ok) {
            if (method == "POST") {
                // offer to send password reset here...
            }
            onClose();
        }
    };

    const emailInput = buildEmailInput(user, register);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <TextInput label="Name" registerAs="fullName" register={register} required />
                <label className="user-edit-field-label">
                    <div className="user-edit-field-label-text">Email</div>
                    {emailInput}
                </label>
                <label className="user-edit-field-label">
                    <div className="user-edit-field-label-text">Role</div>
                    <select className="user-edit-field" {...register("roleId")}>
                        {roles.map(role => <option key={role.id} value={role.id}>{role.name}</option> )}
                    </select>
                </label>
                <table className="database-access-table">
                    <thead>
                        <tr>
                            <th width="60%" className="user-edit-field-label-text">Active</th>
                            <th width="40%" className="user-edit-field-label-text">Last Login</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((r, index) =>
                            <tr key={r.id}>
                                <td width="60%">
                                    <span>
                                        <input type="checkbox" {...register(`agencyTenants.${index}.accessAllowed`)} />
                                        <label htmlFor="dbAccess" className="user-edit-checkbox-label-text">{r.agencyName}</label>
                                    </span>
                                </td>
                                <td width="40%">{r.lastLogin ? new Date(`${r.lastLogin}Z`).toLocaleString() : ""}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button className="button-base" onClick={onCancel}>
                    Cancel
                </button>
                <button type="submit" className="button-base">
                    Save
                </button>
            </Modal.Footer>
        </form>
    );
}

UserForm.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired
}