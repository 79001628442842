import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useWatch } from "react-hook-form";
import UserChangePassword from '../Users/UserChangePassword'
import UserForgotPassword from '../Users/UserForgotPassword'

import './Login.css';
import logo from '../../images/2019_SA_Logo_Navy_468.png';

async function loginUser(credentials, setSessionToken) {
    const OK = 200;
    const Accepted = 202;

    const response = await fetch("/api/login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(credentials)
    });

    if (response.status === OK) {
        const token = await response.json();
        setSessionToken(token);
        return "AUTHORIZED";
    } else if (response.status === Accepted) {
        // Password change required.
        return "CHANGEPASSWORD";
    }

    return "UNAUTHORIZED";
}

export default function Login({ setSessionToken }) {
    const { register, control, handleSubmit } = useForm();
    const username = useWatch({ control, name: "username" });
    const password = useWatch({ control, name: "password" });

    const [loginError, setLoginError] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    const onSubmit = async data => {
        let result = await loginUser({
            email: data.username,
            password: data.password
        }, setSessionToken);

        // Dont call setLoginError if login was successful because then we wont be re-rendering anyway.
        // Otherise get "Can't perform a React state update on an unmounted component" message in console.
        if (result == "UNAUTHORIZED") {
            setLoginError(true);
        } else if (result == "CHANGEPASSWORD") {
            console.log('Setting showChangePassword to true...');
            setShowChangePassword(true);
        }
    }

    let error = null;
    if (loginError) {
        error = (<div className="login-invalid">Not a valid username or password</div>);
    }
    
    const forgotPassword = username ? <UserForgotPassword userEmail={username} /> : "";
    let changePassword = "";
    if (showChangePassword) {
        const onChangeSuccess = async (email, password) => {
            setShowChangePassword(false);

            let result = await loginUser({
                email: email,
                password: password
            }, setSessionToken);
        };
        changePassword = (<UserChangePassword userEmail={username} oldPassword={password} showModal={showChangePassword} onChangeSuccess={onChangeSuccess} />);
    }

    return (
        <div className="login-wrapper">
            <img src={logo} alt="Special Agent, Inc." className="logo" />
            <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                <div>
                    <label className="login-field-label">
                        <span className="login-field-label-text">Username</span>
                        <input type="text" className="login-field" {...register("username", { required: true })} />
                    </label>
                </div>
                <div>
                    <label className="login-field-label">
                        <span className="login-field-label-text">Password</span>
                        <input type="password" className="login-field" {...register("password", { required: true })} />
                    </label>
                </div>
                <div>
                    <button type="submit" className="login-submit">LOGON</button>
                </div>
                { error }
            </form>
            {forgotPassword}
            {changePassword}
        </div>
    )
}

Login.propTypes = {
    setSessionToken: PropTypes.func.isRequired
}