import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';

export default function UserChangePassword({ userEmail, oldPassword, showModal, onChangeSuccess }) {
    const [show, setShow] = useState(showModal);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, formState, control, handleSubmit, getValues } = useForm({
        defaultValues: ""
    });

    const onSubmit = async (data) => {

        const param = {
            email: userEmail,
            oldPassword: oldPassword,
            newPassword: data.newPassword
        };

        const result = await fetch("/api/users/password", {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(param)
        });

        if (result.ok) {
            setShow(false);
            onChangeSuccess(userEmail, data.newPassword);
        }
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered dialogClassName="user-edit-modal-size" contentClassName="user-edit-modal-content">
                <Modal.Header closeButton>
                    <Modal.Title><h2>Change Password</h2></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <label className="user-edit-field-label">
                            <div className="user-edit-field-label-text">New Password</div>
                            <input className="user-edit-field" type="password" {...register("newPassword")} required />
                        </label>
                        <label className="user-edit-field-label">
                            <div className="user-edit-field-label-text">Verify</div>
                            <input className="user-edit-field" type="password" {...register("verify", { validate: value => value === getValues("newPassword") })} required />
                            {formState.errors.verify ? <div className="user-password-change-invalid">Password and verify must be the same</div> : ""}
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="button-base" onClick={handleClose}>
                            Cancel
                        </button>
                        <button type="submit" className="button-base">
                            Ok
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

UserChangePassword.propTypes = {
    userEmail: PropTypes.string.isRequired,
    oldPassword: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired,
    onChangeSuccess: PropTypes.func.isRequired
}