import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import './PaymentMethods.css'

export default function PaymentMethodAddForm({ handleClose, handlePaymentMethodAdded }) {

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // https://stripe.com/docs/payments/quickstart
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded
            // Make sure to disable form submission until Stripe.js has loaded
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_STRIPE_CONFIRM_SETUP_REDIRECT_URL
            },
            redirect: 'if_required'
        });

        if (!error) {
            handlePaymentMethodAdded();
            handleClose();
        } else if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </Modal.Body>
            <Modal.Footer>
                <button className="button-base" onClick={handleClose}>Cancel</button>
                <button className="button-base" disabled={isLoading || !stripe || !elements}>
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Save"}
                    </span>
                </button>
            </Modal.Footer>
        </form>
    );
}

PaymentMethodAddForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handlePaymentMethodAdded: PropTypes.func.isRequired
}
