import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import UserForm from './UserForm'

import './Users.css'
import './UserEdit.css'

export default function UserEdit({ user, roles, onClose }) {
    const [show, setShow] = useState(false);

    const handleCancelClick = () => {
        setShow(false);
    };
    const handleClose = () => {
        setShow(false);
        onClose();
    }
    const handleShow = () => setShow(true);

    let openMechanism = (
        <a href='#!' variant="primary" className="user-list-link" onClick={handleShow}>
            Edit
        </a>
    );
    let actionDescription = "Edit";

    if (!user.id) {
        openMechanism = (
            <button className="add-user-button button-base" onClick={handleShow}>
                Add User
            </button>
        );
        actionDescription = "Add";
    }

    if (!show) {
        return (
            <div>
                {openMechanism}
            </div>
        );
    } else if (show) {

        return (
            <div>
                {openMechanism}

                <Modal show={show} onHide={handleCancelClick} centered dialogClassName="user-edit-modal-size" contentClassName="user-edit-modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title><h2>{actionDescription} User</h2></Modal.Title>
                    </Modal.Header>
                    <UserForm user={user} roles={roles} onClose={handleClose} onCancel={handleCancelClick} />
                </Modal>
            </div>
        );
    }
}

UserEdit.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired
}