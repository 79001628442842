import React, { Component } from 'react';
import BillingSummary from './BillingSummary/BillingSummary'
import Databases from './Databases/Databases'
import Users from './Users/Users'
import { getSessionToken, getCurrentRoleFromToken } from '../utils/TokenUtils';

import './Home.css'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            supportAmount: 0.00, databases: [], parentName: "", users: [], roles: [], paymentMethods: [] };
    }

    async componentDidMount() {
        await this.populate();
    }

    render() {
        const onEditClose = () => this.refreshUsers();
        const userCount = this.state.users.filter(u => u.agencyTenants.find(tenant => tenant.accessAllowed)).length;
        const role = getCurrentRoleFromToken();
        const defaultPaymentMethod = this.state.paymentMethods.length > 0 ? this.state.paymentMethods.find(i => i.isDefault) : null;

        if (role == '') {
            return '';
        }

        const billingSummary = role != "Admin" ? '' : (<BillingSummary support={this.state.support} parentName={this.state.parentName} databaseCount={this.state.databases.length} userCount={userCount} defaultPaymentMethod={defaultPaymentMethod} />);
        const userList = role != "Admin" ? '' : <Users users={this.state.users} roles={this.state.roles} databases={this.state.databases} onEditClose={onEditClose} />;
        return (
            <div>
                <h1 className="home-header">{this.state.parentName}</h1>
                {billingSummary}
                <Databases databases={this.state.databases} />
                {userList}
            </div>
        );
    }

    async populate() {
        const token = getSessionToken();
        const role = getCurrentRoleFromToken();

        if (token) {
            await this.populateParentName(token);
            if (role == "Admin") {
                var promises = new Array();
                promises.push(this.populateSupport(token));
                promises.push(this.populatePaymentMethods(token));
                promises.push(this.populateUsers(token));
                promises.push(this.populateRoles(token));

                await Promise.all(promises);
            }

            await this.populateDatabases(token);
        }
    }

    async refreshUsers() {
        const token = getSessionToken();
        await this.populateUsers(token);
    }

    async populateParentName(token) {
        const response = await fetch("/api/agencytenant/parent", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, parentName: data.agencyName });
    }

    async populateSupport(token) {
        const response = await fetch("/api/agencytenant/support", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, support: data });
    }

    async populatePaymentMethods(token) {
        const response = await fetch("/api/billing/paymentmethods", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, paymentMethods: data });
    }

    async populateDatabases(token) {
        const response = await fetch("/api/agencytenant", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, databases: data });
    }

    async populateUsers(token) {
        const response = await fetch("/api/users", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        console.log("populate users");
        const data = await response.json();
        this.setState({ ...this.state, users: data });
    }

    async populateRoles(token) {
        const response = await fetch("/api/role", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, roles: data });
    }
}
