import React, { Component } from 'react';
import BillingCustomerContactChange from './BillingCustomerContactChange/BillingCustomerContactChange'
import PaymentMethods from './PaymentMethods/PaymentMethods'
import BillingHistory from './BillingHistory/BillingHistory'
import { getSessionToken } from '../utils/TokenUtils';
import { getOrdinal } from '../utils/StringUtils';

import './Home.css'
import './Billing.css'

export class Billing extends Component {
    static displayName = Billing.name;

    // Support and parentName are passed from state in Link
    // This was done the following way prior to updating to react-router v6
    //          https://stackoverflow.com/questions/61035048/react-how-to-pass-in-props-from-a-link-on-react-router?rq=3
    // More info on how to get values here:
    //          https://reactrouter.com/en/main/components/link
    //          https://stackoverflow.com/questions/73461829/is-it-possible-to-have-a-uselocation-inside-the-body-of-a-class-component-rea
    //          https://stackoverflow.com/questions/56718921/history-no-restricted-globals-and-window-history
    constructor(props) {
        super(props);
        this.state = {
            support: window.history.state.usr.support,
            parentName: window.history.state.usr.parentName,
            billingCustomer: null,
            paymentMethods: null,
            invoices: null,
            stripePromise: this.props.stripePromise
        };
        // https://stackoverflow.com/questions/32317154/react-uncaught-typeerror-cannot-read-property-setstate-of-undefined
        this.setPaymentMethodsState = this.setPaymentMethodsState.bind(this);
        this.paymentMethodAdded = this.paymentMethodAdded.bind(this);
    }

    async componentDidMount() {
        await this.populate();
    }

    render() {

        const email = this.state.billingCustomer ? this.state.billingCustomer.email : "";
        const dateText = this.state.support ? getOrdinal(this.state.support.monthDay) : "";

        const handleChangeContactClick = async (e) => {
            e.preventDefault();

            this.setState({ ...this.state, showContactChange: true });
        };

        const handleChangeContactClose = () => {
            this.setState({ ...this.state, showContactChange: false });
        };

        const handleChangeContactSubmit = async (email) => {

            let updatedBillingCustomer = this.state.billingCustomer;
            updatedBillingCustomer.email = email;
            this.setState({ ...this.state, billingCustomer: updatedBillingCustomer});
        };

        // Only render BillingCustomerContactChange if there is an email address to change.
        // Without this, react-hook-form default values do not get set, since the first time the component is rendered the value is null
        let contactChange = '';
        if (email) {
            contactChange = (
                <BillingCustomerContactChange
                    currentEmail={email}
                    show={this.state.showContactChange}
                    onClose={handleChangeContactClose}
                    onSubmit={handleChangeContactSubmit}
                />
            );
        }

        return (
            <div>
                <div className="billing-agency-name">{this.state.parentName}</div>
                <h1 className="billing-header">Billing Settings</h1>

                <h2 className="section-header">Billing Details</h2>
                <div className="billing-details-wrapper">
                    <div className="billing-details-section-wrapper col1">
                        <div className="billing-details-section-header-text">Billing contact:</div>
                        <div className="billing-details-section-text">{email}</div>
                        <div className="billing-details-section-subtext"><a href="" onClick={handleChangeContactClick}>Change contact</a></div>
                    </div>
                    <div className="billing-details-section-wrapper col2">
                        <div className="billing-details-section-header-text">Billing date:</div>
                        <div className="billing-details-section-text">Autopay on the {dateText} of every month</div>
                        <div className="billing-details-section-subtext billing-detail-section-semi-transparent">Failed payments may be retried up to 3 times.</div>
                    </div>
                </div>

                {contactChange}

                <PaymentMethods
                    paymentMethods={this.state.paymentMethods}
                    setPaymentMethodsState={this.setPaymentMethodsState}
                    stripePromise={this.state.stripePromise}
                    handlePaymentMethodAdded={this.paymentMethodAdded}
                />
                <BillingHistory invoices={this.state.invoices} />
            </div>
        );
    }

    async populate() {
        const token = getSessionToken();

        var promises = new Array();
        promises.push(this.populateBillingCustomer(token));
        promises.push(this.populatePaymentMethods(token));
        promises.push(this.populateInvoices(token));
        await Promise.all(promises);
    }

    async populateBillingCustomer(token) {
        const response = await fetch("/api/billing/customer", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, billingCustomer: data });
    }

    async paymentMethodAdded() {
        await this.populatePaymentMethods(getSessionToken());
    }

    async populatePaymentMethods(token) {
        const response = await fetch("/api/billing/paymentmethods", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setPaymentMethodsState(data);
    }

    setPaymentMethodsState(data) {
        this.setState({ ...this.state, paymentMethods: data });
    }

    async populateInvoices(token) {
        const response = await fetch("/api/billing/invoices", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        this.setState({ ...this.state, invoices: data });
    }
}
