import React from 'react';

import './PaymentMethods.css'

// Card icons are from https://www.dreamstale.com/free-download-payment-method-vector-icons/
import amex from '../../images/amex.png'
import discover from '../../images/discover.png'
import mastercard from '../../images/mastercard.png'
import visa from '../../images/visa.png'
// ACH/Bank icon from https://www.freepik.com/search?format=search&last_filter=query&last_value=Bank&query=Bank&type=icon
import bank from '../../images/bank.png'

function getPaymentMethodLogo(name) {

    let logo = '';
    name = name.toLowerCase();

    if (name == 'amex') {
        logo = (<img src={amex} alt="American Express" className="card-logo" />);
    } else if (name == 'discover') {
        logo = (<img src={discover} alt="Discover" className="card-logo" />);
    } else if (name == 'mastercard') {
        logo = (<img src={mastercard} alt="MasterCard" className="card-logo" />);
    } else if (name == 'visa') {
        logo = (<img src={visa} alt="Visa" className="card-logo" />);
    } else {
        logo = (<img src={bank} alt="ACH" className="ach-logo" />);
    }

    return logo;
}

export default function PaymentMethodLogo({ paymentMethodName }) {

    return (getPaymentMethodLogo(paymentMethodName));
}